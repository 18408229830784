import React, { useEffect, useMemo, useState } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import { boundsToLocation, userLocation } from '../../util/maps';
import { types as sdkTypes } from '../../util/sdkLoader';
import defaultLocations from '../../default-location-searches';
import config from '../../config';
import {
  Page,
  SectionHero,
  IconSpinner,
  LandingCard,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LandingFooter,
  PrimaryButton,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
  manualLoadData,
  fetchCategoriesNear,
  fetchNewListingsNear,
  fetchWantedListingsNear,
  CATEGORIES_RADIUS,
} from './LandingPage.duck';

import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import defaultImage from '../../assets/LogoLight.png';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import { convertMetersToMiles } from '../../util/data';

const { LatLng } = sdkTypes;
const seattleCenter = boundsToLocation(
  defaultLocations.find(l => l.id === 'default-seattle').predictionPlace.bounds
);

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    scrollingDisabled,
    onLoadListings,
    isAuthenticated,
    // top categories near you
    fetchCategoriesNearInProgress,
    fetchCategoriesNearError,
    canLoadMoreCategories,
    categoriesNear,
    onFetchCategoriesNear,
    // new listings near
    fetchNewListingsNearInProgress,
    fetchNewListingsNearError,
    newListingsNearPagination,
    newListingsNear,
    onFetchNewListingsNear,
    // wanted near
    fetchWantedListingsNearInProgress,
    fetchWantedListingsNearError,
    wantedListingsNearPagination,
    wantedListingsNear,
    onFetchWantedListingsNear,
  } = props;
  const [userLatLng, setUserLatLng] = useState(null);
  const [latLngLoading, setLatLngLoading] = useState(false);
  const [askedForLatLng, setAskedForLatLng] = useState(false);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${defaultImage}`;

  const fetchCategoriesNearErrorMessage = useMemo(
    () => intl.formatMessage({ id: 'LandingPage.fetchCategoriesError' }),
    [intl]
  );
  const fetchNewListingsNearErrorMessage = useMemo(
    () => intl.formatMessage({ id: 'LandingPage.fetchListingsErorr' }),
    [intl]
  );
  const fetchWantedListingsNearErrorMessage = useMemo(
    () => intl.formatMessage({ id: 'LandingPage.fetchWantedError' }),
    [intl]
  );

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        setLatLngLoading(true);
        const location = await userLocation();
        setUserLatLng(location);
      } catch {
      } finally {
        setLatLngLoading(false);
        setAskedForLatLng(true);
      }
    };
    getUserLocation();
  }, []);

  const latlng = useMemo(
    () =>
      userLatLng
        ? userLatLng 
        : seattleCenter 
          ? new LatLng(seattleCenter.lat, seattleCenter.lng)
          : null,
    [userLatLng]
  );
  useEffect(() => {
    if (askedForLatLng && latlng) onLoadListings(1, latlng);
  }, [latlng, askedForLatLng, onLoadListings]);
  
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: defaultImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div>
            <SectionHero history={history} userLatLng={userLatLng} />
          </div>
          <ul className={css.sections}>
            <div className={css.section}>
              <h1 className={css.sectionTitle}>
                <FormattedMessage id="LandingPage.topCategoriesTitle" />
              </h1>
              <h3 className={css.sectionSubtitle}>
                <FormattedMessage id="LandingPage.topCategoriesSubtitle" />
              </h3>
              {(fetchCategoriesNearInProgress && categoriesNear.length === 0) || latLngLoading ? (                        
                <IconSpinner className={css.iconSpinner} />
              ) : fetchCategoriesNearError ? (
                <div className={css.error}>{fetchCategoriesNearErrorMessage}</div>
              ) : (
                <div className={css.listings}>
                  {categoriesNear.map(c => (
                    <LandingCard
                      key={c._id}
                      intl={intl}
                      listing={c}
                      userLatLng={userLatLng}
                      isCategory
                    />
                  ))}
                </div>
              )}
              {!(fetchCategoriesNearInProgress || latLngLoading) && categoriesNear.length === 0 && (
                <h3 className={css.alignCenter}>
                  {intl.formatMessage(
                    { id: 'LandingPage.noItems' },
                    {
                      radiusMaybe:                      
                        convertMetersToMiles(CATEGORIES_RADIUS) > 1
                          ? intl.formatMessage(
                              { id: 'LandingPage.inRadiusOf' },
                              { radius: convertMetersToMiles(CATEGORIES_RADIUS).toFixed(2) }
                            )
                          : null,
                    }
                  )}
                </h3>
              )}
              {canLoadMoreCategories ? (
                <PrimaryButton
                  inProgress={fetchCategoriesNearInProgress}
                  className={css.viewMore}
                  onClick={() => onFetchCategoriesNear(latlng)}
                >
                  <FormattedMessage id="LandingPage.viewMore" />
                </PrimaryButton>
              ) : null}
            </div>
            <div className={css.section}>
              <h1 className={css.sectionTitle}>
                <FormattedMessage id="LandingPage.newListingsTitle" />
              </h1>
              <h3 className={css.sectionSubtitle}>
                <FormattedMessage id="LandingPage.newListingsSubtitle" />
              </h3>
              {(fetchNewListingsNearInProgress && newListingsNear.length === 0) || latLngLoading ? (
                <IconSpinner className={css.iconSpinner} />
              ) : fetchNewListingsNearError ? (
                <div className={css.error}>{fetchNewListingsNearErrorMessage}</div>
              ) : (
                <div className={css.listings}>
                  {newListingsNear.map(l => (
                    <LandingCard key={l.sharetribeId} intl={intl} listing={l} />
                  ))}
                </div>
              )}

              {!(fetchNewListingsNearInProgress || latLngLoading) &&
                newListingsNear.length === 0 && (
                  <h3 className={css.alignCenter}>
                    {intl.formatMessage({ id: 'LandingPage.noItems' }, { radiusMaybe: null })}
                  </h3>
                )}

              {newListingsNearPagination &&
              newListingsNearPagination.page < newListingsNearPagination.totalPages ? (
                <PrimaryButton
                  inProgress={fetchNewListingsNearInProgress}
                  className={css.viewMore}
                  onClick={() =>
                    onFetchNewListingsNear(newListingsNearPagination?.page + 1 || 1, latlng)
                  }
                >
                  <FormattedMessage id="LandingPage.viewMore" />
                </PrimaryButton>
              ) : null}
            </div>
            <div className={css.section}>
              <h1 className={css.sectionTitle}>
                <FormattedMessage id="LandingPage.wantedItemsTitle" />
              </h1>
              <h3 className={css.sectionSubtitle}>
                <FormattedMessage id="LandingPage.wantedItemsSubtitle" />
              </h3>
              {(fetchWantedListingsNearInProgress && wantedListingsNear.length === 0) ||
              latLngLoading ? (
                <IconSpinner className={css.iconSpinner} />
              ) : fetchWantedListingsNearError ? (
                <div className={css.error}>{fetchWantedListingsNearErrorMessage}</div>
              ) : (
                <div className={css.listings}>
                  {wantedListingsNear.map(l => (
                    <LandingCard key={l.sharetribeId} intl={intl} listing={l} />
                  ))}
                </div>
              )}

              {!(fetchWantedListingsNearInProgress || latLngLoading) &&
                wantedListingsNear.length === 0 && (
                  <h3 className={css.alignCenter}>
                    {intl.formatMessage({ id: 'LandingPage.noItems' }, { radiusMaybe: null })}
                  </h3>
                )}

              {wantedListingsNearPagination &&
              wantedListingsNearPagination.page < wantedListingsNearPagination.totalPages ? (
                <PrimaryButton
                  inProgress={fetchWantedListingsNearInProgress}
                  className={css.viewMore}
                  onClick={() =>
                    onFetchWantedListingsNear(wantedListingsNearPagination?.page + 1 || 1, latlng)
                  }
                >
                  <FormattedMessage id="LandingPage.viewMore" />
                </PrimaryButton>
              ) : null}
            </div>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <LandingFooter isAuthenticated={isAuthenticated} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchCategoriesNearInProgress,
    fetchCategoriesNearError,
    canLoadMoreCategories,
    categoriesNear,
    fetchNewListingsNearInProgress,
    fetchNewListingsNearError,
    newListingsNearPagination,
    newListingsNear,
    fetchWantedListingsNearInProgress,
    fetchWantedListingsNearError,
    wantedListingsNearPagination,
    wantedListingsNear,
  } = state.LandingPage;
  // Footer needs isAuthenticated
  const { isAuthenticated } = state.Auth;

  return {
    isAuthenticated,
    // top categories near you
    fetchCategoriesNearInProgress,
    fetchCategoriesNearError,
    canLoadMoreCategories,
    categoriesNear,
    // new listings near you
    fetchNewListingsNearInProgress,
    fetchNewListingsNearError,
    newListingsNearPagination,
    newListingsNear,
    // wanted items near you
    fetchWantedListingsNearInProgress,
    fetchWantedListingsNearError,
    wantedListingsNearPagination,
    wantedListingsNear,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onLoadListings: (page, latLng) => dispatch(manualLoadData(page, latLng)),
  onFetchCategoriesNear: latLng => dispatch(fetchCategoriesNear(latLng)),
  onFetchNewListingsNear: (page, latLng) => dispatch(fetchNewListingsNear(page, latLng)),
  onFetchWantedListingsNear: (page, latLng) => dispatch(fetchWantedListingsNear(page, latLng)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
